import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";
import { Link } from 'gatsby'


import Tier2Layout from "../../components/content/Tier2Layout"
import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
import ArticleSidebar from "../../components/content/ArticleSidebar"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import '../../styles/tier2.css'
import '../../styles/about.css'
import about_hero from '../../images/about-hero-1.jpg';
import pic_jeof from '../../images/jeofoyster.jpg';


const AboutIndex = () => {
    const pageData = {
                        seo:{
                            title:'About Us',
                            description:"",
                            image:'/images/about-hero-1.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('About',location); 
    },[location]);

    const [showConsultationForm,setShowConsultationForm] = useState(false); 

    function startConsultationRequest(which){ 
        GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
        setShowConsultationForm(true); 
    }
    
  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier2Layout showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

        <div id="About_Hero">
            <div className="mast-seperator">
                <svg id="Tier2MastSeperatorSVG" data-name="Tier2MastSeperatorSVG" xmlns="http://www.w3.org/2000/svg" width="1320" height="96.8978" viewBox="0 0 1320 96.8978">
                <path class="background" d="M1320,51.7988H136.6818C1.0792,51.7988,0,96.8978,0,96.8978V-.3547H1320Z"/>
                <path class="middle-bar" d="M1320,38.7957H94.0934c-80.149,0-94.0934,20-94.0934,20V4.07s3.7773,20,92.9468,20H1320Z"/>
                <path class="upper-bar" d="M1.586,1.4134S17.79,20.75,95.2027,20.75C100.97,20.75,1320,21.0276,1320,21.0276V15.0245S114.4532,15.0121,95.8607,15.0121C20.0737,15.0121,1.586,1.4134,1.586,1.4134Z"/>
                <path class="lower-bar" d="M.0355,66.3013c9.1191-12.2588,35.4369-24.373,103.9433-24.2294,9.7635.02,1216.0212-.2762,1216.0212-.2762v6.0031s-1164.73,1.19-1183.3222,1.19C.2923,48.989,0,91.41,0,91.41Z"/>
                <polygon class="right-arrow" points="1315.675 48.499 1320 48.499 1320 10.898 1278.546 10.898 1315.675 48.499"/>
                </svg>
            </div>
            <div className="image">
                <img src={about_hero} width="100%" alt="Travel Collage" />
            </div>
            <div className="textcopy">
                <h1>Meet Outward Travel</h1>
            </div>
        </div>

        <Row>
            <Col md={7} lg={8} xxl={9} className="Tier2MainText">
                <p>Hello!</p>
                <p>I'm Jeof Oyster, the principal advisor at Outward Travel, and I'm looking forward to helping you 
                    plan an amazing experience whether you're traveling solo, going on a romantic escape, taking 
                    your family on a long-overdue vacation, or organizing a group trip for your friends, fans, or customers. 
                </p>
                <p>I have been traveling all over the world since I was a young'n. I love to be out in the world, seeing 
                    the great sights of history, learning from other cultures, experimenting with new cuisine, and 
                    best of all, relaxing on beautiful beaches. Along the way I've learned a lot about where to go, what to do, 
                    and how to travel, and I want to share that with you. I love travel, I need to travel, and I want to share 
                    that with my customers as they explore the world and their dreams.
                </p>
                <p>Let's explore the world. I know it's kind of a crazy time to be planning a trip, but 
                    the future is looking bright and I'll work with you to ensure you have a safe, fulfilling experience 
                    wherever you want to go. Leverage my expertise and experience.
                </p>
                <p>If you've never worked with a travel advisor before, then this is the right time to see how easy we make it for you. 
                    In normal times travel agents save you over 20+ hours of planning time, ensure every last detail is covered, 
                    are there to help if anything ever goes awry. In these times of COVID-19, we play an even more important role: to 
                    help you travel safely, stay on top of the ever-changing restrictions and rules for traveling, and make all that complexity
                    easy for you to deal with. And as a travel advisor, my duty of care is to ensure you know everything you need to know, and 
                    give you sound, unbiased advice for making your travel dreams come true. You are my customer, and I take our <Link to="/about/codeofconduct">Code of Conduct</Link> very seriously. 
                </p>
                <p>For the remainder of 2021, Outward Travel is not charging any professional services fees. Let's talk about your 
                    next vacation adventure. I want to know all about the places you've been and why you loved them, and I want to 
                    know what makes you happy, what sort of experience you're craving, and what I can do to help you get there.
                </p>
                <p>Let's have a chat. Use the button below to start, call us at (720) 828-7665, or email go@outward.travel.</p>
                <p>I can't wait to meet you. </p>
                
                <ConsultationRequestTrigger color="blue" onClick={()=>{startConsultationRequest('main_content')}} />

            </Col>
            <Col md={5} lg={4} xxl={3}>
                
                <ArticleSidebar id="AboutSidebar" headline={<>Meet Jeof</>} color="blue">
                    <img src={pic_jeof} width="90%" alt="Jeof Oyster" />
                    <p>
                        <b>Favorite Experiences</b>
                        Stargazing on a warm African night
                    </p>
                    <p>
                        <b>Favorite Vacation</b>
                        A week on Isla Mujeres, Mexico
                    </p>
                    <p>
                        <b>Favorite Destination</b>
                        Walt Disney World<sub><small>&reg;</small></sub>
                    </p> 
                </ArticleSidebar>
            </Col>
        </Row>


        </Tier2Layout>
    </main>
  )
}

 
export default AboutIndex;
